// eslint-disable
// ⚠️ DO NOT EDIT ⚠️
// This file is automatically generated, run npm run generate to update

import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  Date: any
}

export type Book = {
  __typename?: 'Book'
  _count: BookCount
  authors: Array<Scalars['String']>
  createdAt: Scalars['Date']
  description: Scalars['String']
  googleId: Scalars['String']
  id: Scalars['ID']
  pageCount: Scalars['Int']
  publishedAt: Scalars['Int']
  subtitle?: Maybe<Scalars['String']>
  thumbnail?: Maybe<Scalars['String']>
  title: Scalars['String']
  url: Scalars['String']
}

export type BookCount = {
  __typename?: 'BookCount'
  recommendations: Scalars['Int']
}

export enum BookFilterType {
  PageCount = 'PAGE_COUNT',
  RecommendationCount = 'RECOMMENDATION_COUNT',
  RecommendedAt = 'RECOMMENDED_AT',
}

export type BookRecommendations = {
  __typename?: 'BookRecommendations'
  count: Scalars['Int']
  recommended: Scalars['Boolean']
}

export type Comment = {
  __typename?: 'Comment'
  author: User
  createdAt: Scalars['Date']
  id: Scalars['ID']
  text: Scalars['String']
  updatedAt: Scalars['Date']
  viewerCanDelete: Scalars['Boolean']
  viewerCanUpdate: Scalars['Boolean']
}

export enum CommentType {
  Book = 'BOOK',
  ReadingSession = 'READING_SESSION',
}

export type CreateBookRecommendationInput = {
  comment?: InputMaybe<Scalars['String']>
  id: Scalars['String']
}

export type CreateReadingSessionInput = {
  bookId: Scalars['ID']
  duration: Scalars['Int']
}

export type EditUserInput = {
  email?: InputMaybe<Scalars['String']>
  name?: InputMaybe<Scalars['String']>
}

export type GetBooksInput = {
  filter?: InputMaybe<BookFilterType>
}

export type Mutation = {
  __typename?: 'Mutation'
  addComment?: Maybe<Comment>
  attendReadingSession?: Maybe<Scalars['Boolean']>
  createBookRecommendation?: Maybe<Book>
  createReadingSession?: Maybe<Scalars['Boolean']>
  editUser?: Maybe<User>
  removeComment?: Maybe<Scalars['Boolean']>
  toggleBookRecommendation?: Maybe<BookRecommendations>
  updateComment?: Maybe<Comment>
  updateReadingSessionPage?: Maybe<Scalars['Boolean']>
}

export type MutationAddCommentArgs = {
  refId: Scalars['ID']
  text: Scalars['String']
  type: CommentType
}

export type MutationAttendReadingSessionArgs = {
  id: Scalars['ID']
}

export type MutationCreateBookRecommendationArgs = {
  data?: InputMaybe<CreateBookRecommendationInput>
}

export type MutationCreateReadingSessionArgs = {
  data: CreateReadingSessionInput
}

export type MutationEditUserArgs = {
  data?: InputMaybe<EditUserInput>
}

export type MutationRemoveCommentArgs = {
  id: Scalars['ID']
}

export type MutationToggleBookRecommendationArgs = {
  id: Scalars['ID']
}

export type MutationUpdateCommentArgs = {
  id: Scalars['ID']
  text: Scalars['String']
}

export type MutationUpdateReadingSessionPageArgs = {
  pageNumber: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  book?: Maybe<Book>
  bookRecommendations?: Maybe<BookRecommendations>
  books: Array<Book>
  comments: Array<Comment>
  readingSession?: Maybe<ReadingSession>
  user?: Maybe<User>
  viewer?: Maybe<User>
}

export type QueryBookArgs = {
  id: Scalars['ID']
}

export type QueryBookRecommendationsArgs = {
  id: Scalars['ID']
}

export type QueryBooksArgs = {
  data?: InputMaybe<GetBooksInput>
}

export type QueryCommentsArgs = {
  refId: Scalars['ID']
  type: CommentType
}

export type QueryUserArgs = {
  id: Scalars['ID']
}

export type ReadingSession = {
  __typename?: 'ReadingSession'
  attending: Scalars['Boolean']
  book: Book
  createdAt: Scalars['Date']
  deadlineAt: Scalars['Date']
  id: Scalars['ID']
  members: Array<ReadingSessionMember>
  viewer?: Maybe<ReadingSessionMember>
}

export type ReadingSessionMember = {
  __typename?: 'ReadingSessionMember'
  createdAt: Scalars['Date']
  pageNumber: Scalars['Int']
  updatedAt: Scalars['Date']
  user: User
}

export enum Role {
  Admin = 'ADMIN',
  Blocked = 'BLOCKED',
  Pending = 'PENDING',
  User = 'USER',
}

export type User = {
  __typename?: 'User'
  email?: Maybe<Scalars['String']>
  emailVerified?: Maybe<Scalars['Date']>
  id: Scalars['ID']
  image?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  role?: Maybe<Role>
}

export type BookRecommendationInfoFragment = {
  __typename: 'BookRecommendations'
  count: number
  recommended: boolean
}

export type BookInfoFragment = {
  __typename: 'Book'
  id: string
  title: string
  thumbnail?: string | null
  authors: Array<string>
  url: string
  pageCount: number
  _count: { __typename?: 'BookCount'; recommendations: number }
}

export type BookInfoDetailFragment = {
  __typename: 'Book'
  subtitle?: string | null
  description: string
  url: string
  googleId: string
  publishedAt: number
  pageCount: number
  createdAt: any
  id: string
  title: string
  thumbnail?: string | null
  authors: Array<string>
  _count: { __typename?: 'BookCount'; recommendations: number }
}

export type CommentInfoFragment = {
  __typename: 'Comment'
  id: string
  text: string
  viewerCanUpdate: boolean
  viewerCanDelete: boolean
  author: {
    __typename: 'User'
    id: string
    name?: string | null
    image?: string | null
    role?: Role | null
  }
}

export type ReadingSessionMemberInfoFragment = {
  __typename?: 'ReadingSessionMember'
  pageNumber: number
  createdAt: any
  updatedAt: any
  user: {
    __typename: 'User'
    id: string
    name?: string | null
    image?: string | null
    role?: Role | null
  }
}

export type ReadingSessionInfoFragment = {
  __typename: 'ReadingSession'
  id: string
  attending: boolean
  deadlineAt: any
  createdAt: any
  book: {
    __typename: 'Book'
    id: string
    title: string
    thumbnail?: string | null
    authors: Array<string>
    url: string
    pageCount: number
    _count: { __typename?: 'BookCount'; recommendations: number }
  }
  members: Array<{
    __typename?: 'ReadingSessionMember'
    pageNumber: number
    createdAt: any
    updatedAt: any
    user: {
      __typename: 'User'
      id: string
      name?: string | null
      image?: string | null
      role?: Role | null
    }
  }>
  viewer?: {
    __typename?: 'ReadingSessionMember'
    pageNumber: number
    createdAt: any
    updatedAt: any
    user: {
      __typename: 'User'
      id: string
      name?: string | null
      image?: string | null
      role?: Role | null
    }
  } | null
}

export type UserInfoFragment = {
  __typename: 'User'
  id: string
  name?: string | null
  image?: string | null
  role?: Role | null
}

export type UserSettingsFragment = {
  __typename?: 'User'
  email?: string | null
  emailVerified?: any | null
}

export type CreateBookRecommendationMutationVariables = Exact<{
  data?: InputMaybe<CreateBookRecommendationInput>
}>

export type CreateBookRecommendationMutation = {
  __typename?: 'Mutation'
  createBookRecommendation?: {
    __typename: 'Book'
    id: string
    title: string
    thumbnail?: string | null
    authors: Array<string>
    url: string
    pageCount: number
    _count: { __typename?: 'BookCount'; recommendations: number }
  } | null
}

export type ToggleBookRecommendationMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ToggleBookRecommendationMutation = {
  __typename?: 'Mutation'
  toggleBookRecommendation?: {
    __typename: 'BookRecommendations'
    count: number
    recommended: boolean
  } | null
}

export type AddCommentMutationVariables = Exact<{
  refId: Scalars['ID']
  type: CommentType
  text: Scalars['String']
}>

export type AddCommentMutation = {
  __typename?: 'Mutation'
  addComment?: {
    __typename: 'Comment'
    id: string
    text: string
    viewerCanUpdate: boolean
    viewerCanDelete: boolean
    author: {
      __typename: 'User'
      id: string
      name?: string | null
      image?: string | null
      role?: Role | null
    }
  } | null
}

export type UpdateCommentMutationVariables = Exact<{
  id: Scalars['ID']
  text: Scalars['String']
}>

export type UpdateCommentMutation = {
  __typename?: 'Mutation'
  updateComment?: {
    __typename: 'Comment'
    id: string
    text: string
    viewerCanUpdate: boolean
    viewerCanDelete: boolean
    author: {
      __typename: 'User'
      id: string
      name?: string | null
      image?: string | null
      role?: Role | null
    }
  } | null
}

export type RemoveCommentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type RemoveCommentMutation = {
  __typename?: 'Mutation'
  removeComment?: boolean | null
}

export type CreateReadingSessionMutationVariables = Exact<{
  data: CreateReadingSessionInput
}>

export type CreateReadingSessionMutation = {
  __typename?: 'Mutation'
  createReadingSession?: boolean | null
}

export type AttendReadingSessionMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type AttendReadingSessionMutation = {
  __typename?: 'Mutation'
  attendReadingSession?: boolean | null
}

export type UpdateReadingSessionPageMutationVariables = Exact<{
  pageNumber: Scalars['Int']
}>

export type UpdateReadingSessionPageMutation = {
  __typename?: 'Mutation'
  updateReadingSessionPage?: boolean | null
}

export type EditUserMutationVariables = Exact<{
  data?: InputMaybe<EditUserInput>
}>

export type EditUserMutation = {
  __typename?: 'Mutation'
  editUser?: {
    __typename: 'User'
    id: string
    name?: string | null
    image?: string | null
    role?: Role | null
  } | null
}

export type GetBookRecommendationsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetBookRecommendationsQuery = {
  __typename?: 'Query'
  bookRecommendations?: {
    __typename: 'BookRecommendations'
    count: number
    recommended: boolean
  } | null
}

export type GetBooksQueryVariables = Exact<{
  data?: InputMaybe<GetBooksInput>
}>

export type GetBooksQuery = {
  __typename?: 'Query'
  books: Array<{
    __typename: 'Book'
    id: string
    title: string
    thumbnail?: string | null
    authors: Array<string>
    url: string
    pageCount: number
    _count: { __typename?: 'BookCount'; recommendations: number }
  }>
}

export type GetBookQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetBookQuery = {
  __typename?: 'Query'
  book?: {
    __typename: 'Book'
    id: string
    title: string
    thumbnail?: string | null
    authors: Array<string>
    url: string
    pageCount: number
    subtitle?: string | null
    description: string
    googleId: string
    publishedAt: number
    createdAt: any
    _count: { __typename?: 'BookCount'; recommendations: number }
  } | null
}

export type GetCommentsQueryVariables = Exact<{
  refId: Scalars['ID']
  type: CommentType
}>

export type GetCommentsQuery = {
  __typename?: 'Query'
  comments: Array<{
    __typename: 'Comment'
    id: string
    text: string
    viewerCanUpdate: boolean
    viewerCanDelete: boolean
    author: {
      __typename: 'User'
      id: string
      name?: string | null
      image?: string | null
      role?: Role | null
    }
  }>
}

export type ReadingSessionQueryVariables = Exact<{ [key: string]: never }>

export type ReadingSessionQuery = {
  __typename?: 'Query'
  readingSession?: {
    __typename: 'ReadingSession'
    id: string
    attending: boolean
    deadlineAt: any
    createdAt: any
    book: {
      __typename: 'Book'
      id: string
      title: string
      thumbnail?: string | null
      authors: Array<string>
      url: string
      pageCount: number
      _count: { __typename?: 'BookCount'; recommendations: number }
    }
    members: Array<{
      __typename?: 'ReadingSessionMember'
      pageNumber: number
      createdAt: any
      updatedAt: any
      user: {
        __typename: 'User'
        id: string
        name?: string | null
        image?: string | null
        role?: Role | null
      }
    }>
    viewer?: {
      __typename?: 'ReadingSessionMember'
      pageNumber: number
      createdAt: any
      updatedAt: any
      user: {
        __typename: 'User'
        id: string
        name?: string | null
        image?: string | null
        role?: Role | null
      }
    } | null
  } | null
}

export type UserQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type UserQuery = {
  __typename?: 'Query'
  user?: {
    __typename: 'User'
    id: string
    name?: string | null
    image?: string | null
    role?: Role | null
  } | null
}

export type ViewerQueryVariables = Exact<{ [key: string]: never }>

export type ViewerQuery = {
  __typename?: 'Query'
  viewer?: {
    __typename: 'User'
    id: string
    name?: string | null
    image?: string | null
    role?: Role | null
  } | null
}

export type GetViewerWithSettingsQueryVariables = Exact<{
  [key: string]: never
}>

export type GetViewerWithSettingsQuery = {
  __typename?: 'Query'
  viewer?: {
    __typename: 'User'
    id: string
    name?: string | null
    image?: string | null
    role?: Role | null
    email?: string | null
    emailVerified?: any | null
  } | null
}

export const BookRecommendationInfoFragmentDoc = gql`
  fragment BookRecommendationInfo on BookRecommendations {
    __typename
    count
    recommended
  }
`
export const BookInfoFragmentDoc = gql`
  fragment BookInfo on Book {
    __typename
    id
    title
    thumbnail
    authors
    url
    pageCount
    _count {
      recommendations
    }
  }
`
export const BookInfoDetailFragmentDoc = gql`
  fragment BookInfoDetail on Book {
    ...BookInfo
    subtitle
    description
    url
    googleId
    publishedAt
    pageCount
    createdAt
  }
  ${BookInfoFragmentDoc}
`
export const UserInfoFragmentDoc = gql`
  fragment UserInfo on User {
    __typename
    id
    name
    image
    role
  }
`
export const CommentInfoFragmentDoc = gql`
  fragment CommentInfo on Comment {
    __typename
    id
    text
    author {
      ...UserInfo
    }
    viewerCanUpdate
    viewerCanDelete
  }
  ${UserInfoFragmentDoc}
`
export const ReadingSessionMemberInfoFragmentDoc = gql`
  fragment ReadingSessionMemberInfo on ReadingSessionMember {
    user {
      ...UserInfo
    }
    pageNumber
    createdAt
    updatedAt
  }
  ${UserInfoFragmentDoc}
`
export const ReadingSessionInfoFragmentDoc = gql`
  fragment ReadingSessionInfo on ReadingSession {
    __typename
    id
    attending
    book {
      ...BookInfo
    }
    deadlineAt
    createdAt
    members {
      ...ReadingSessionMemberInfo
    }
    viewer {
      ...ReadingSessionMemberInfo
    }
  }
  ${BookInfoFragmentDoc}
  ${ReadingSessionMemberInfoFragmentDoc}
`
export const UserSettingsFragmentDoc = gql`
  fragment UserSettings on User {
    email
    emailVerified
  }
`
export const CreateBookRecommendationDocument = gql`
  mutation createBookRecommendation($data: CreateBookRecommendationInput) {
    createBookRecommendation(data: $data) {
      ...BookInfo
    }
  }
  ${BookInfoFragmentDoc}
`
export type CreateBookRecommendationMutationFn = Apollo.MutationFunction<
  CreateBookRecommendationMutation,
  CreateBookRecommendationMutationVariables
>

/**
 * __useCreateBookRecommendationMutation__
 *
 * To run a mutation, you first call `useCreateBookRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookRecommendationMutation, { data, loading, error }] = useCreateBookRecommendationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBookRecommendationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBookRecommendationMutation,
    CreateBookRecommendationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateBookRecommendationMutation,
    CreateBookRecommendationMutationVariables
  >(CreateBookRecommendationDocument, options)
}
export type CreateBookRecommendationMutationHookResult = ReturnType<
  typeof useCreateBookRecommendationMutation
>
export type CreateBookRecommendationMutationResult =
  Apollo.MutationResult<CreateBookRecommendationMutation>
export type CreateBookRecommendationMutationOptions =
  Apollo.BaseMutationOptions<
    CreateBookRecommendationMutation,
    CreateBookRecommendationMutationVariables
  >
export const ToggleBookRecommendationDocument = gql`
  mutation toggleBookRecommendation($id: ID!) {
    toggleBookRecommendation(id: $id) {
      ...BookRecommendationInfo
    }
  }
  ${BookRecommendationInfoFragmentDoc}
`
export type ToggleBookRecommendationMutationFn = Apollo.MutationFunction<
  ToggleBookRecommendationMutation,
  ToggleBookRecommendationMutationVariables
>

/**
 * __useToggleBookRecommendationMutation__
 *
 * To run a mutation, you first call `useToggleBookRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBookRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBookRecommendationMutation, { data, loading, error }] = useToggleBookRecommendationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleBookRecommendationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleBookRecommendationMutation,
    ToggleBookRecommendationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ToggleBookRecommendationMutation,
    ToggleBookRecommendationMutationVariables
  >(ToggleBookRecommendationDocument, options)
}
export type ToggleBookRecommendationMutationHookResult = ReturnType<
  typeof useToggleBookRecommendationMutation
>
export type ToggleBookRecommendationMutationResult =
  Apollo.MutationResult<ToggleBookRecommendationMutation>
export type ToggleBookRecommendationMutationOptions =
  Apollo.BaseMutationOptions<
    ToggleBookRecommendationMutation,
    ToggleBookRecommendationMutationVariables
  >
export const AddCommentDocument = gql`
  mutation addComment($refId: ID!, $type: CommentType!, $text: String!) {
    addComment(refId: $refId, type: $type, text: $text) {
      ...CommentInfo
    }
  }
  ${CommentInfoFragmentDoc}
`
export type AddCommentMutationFn = Apollo.MutationFunction<
  AddCommentMutation,
  AddCommentMutationVariables
>

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      refId: // value for 'refId'
 *      type: // value for 'type'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useAddCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCommentMutation,
    AddCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(
    AddCommentDocument,
    options
  )
}
export type AddCommentMutationHookResult = ReturnType<
  typeof useAddCommentMutation
>
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<
  AddCommentMutation,
  AddCommentMutationVariables
>
export const UpdateCommentDocument = gql`
  mutation updateComment($id: ID!, $text: String!) {
    updateComment(id: $id, text: $text) {
      ...CommentInfo
    }
  }
  ${CommentInfoFragmentDoc}
`
export type UpdateCommentMutationFn = Apollo.MutationFunction<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      text: // value for 'text'
 *   },
 * });
 */
export function useUpdateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommentMutation,
    UpdateCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCommentMutation,
    UpdateCommentMutationVariables
  >(UpdateCommentDocument, options)
}
export type UpdateCommentMutationHookResult = ReturnType<
  typeof useUpdateCommentMutation
>
export type UpdateCommentMutationResult =
  Apollo.MutationResult<UpdateCommentMutation>
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>
export const RemoveCommentDocument = gql`
  mutation removeComment($id: ID!) {
    removeComment(id: $id)
  }
`
export type RemoveCommentMutationFn = Apollo.MutationFunction<
  RemoveCommentMutation,
  RemoveCommentMutationVariables
>

/**
 * __useRemoveCommentMutation__
 *
 * To run a mutation, you first call `useRemoveCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommentMutation, { data, loading, error }] = useRemoveCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCommentMutation,
    RemoveCommentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveCommentMutation,
    RemoveCommentMutationVariables
  >(RemoveCommentDocument, options)
}
export type RemoveCommentMutationHookResult = ReturnType<
  typeof useRemoveCommentMutation
>
export type RemoveCommentMutationResult =
  Apollo.MutationResult<RemoveCommentMutation>
export type RemoveCommentMutationOptions = Apollo.BaseMutationOptions<
  RemoveCommentMutation,
  RemoveCommentMutationVariables
>
export const CreateReadingSessionDocument = gql`
  mutation createReadingSession($data: CreateReadingSessionInput!) {
    createReadingSession(data: $data)
  }
`
export type CreateReadingSessionMutationFn = Apollo.MutationFunction<
  CreateReadingSessionMutation,
  CreateReadingSessionMutationVariables
>

/**
 * __useCreateReadingSessionMutation__
 *
 * To run a mutation, you first call `useCreateReadingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReadingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReadingSessionMutation, { data, loading, error }] = useCreateReadingSessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReadingSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReadingSessionMutation,
    CreateReadingSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateReadingSessionMutation,
    CreateReadingSessionMutationVariables
  >(CreateReadingSessionDocument, options)
}
export type CreateReadingSessionMutationHookResult = ReturnType<
  typeof useCreateReadingSessionMutation
>
export type CreateReadingSessionMutationResult =
  Apollo.MutationResult<CreateReadingSessionMutation>
export type CreateReadingSessionMutationOptions = Apollo.BaseMutationOptions<
  CreateReadingSessionMutation,
  CreateReadingSessionMutationVariables
>
export const AttendReadingSessionDocument = gql`
  mutation attendReadingSession($id: ID!) {
    attendReadingSession(id: $id)
  }
`
export type AttendReadingSessionMutationFn = Apollo.MutationFunction<
  AttendReadingSessionMutation,
  AttendReadingSessionMutationVariables
>

/**
 * __useAttendReadingSessionMutation__
 *
 * To run a mutation, you first call `useAttendReadingSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttendReadingSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attendReadingSessionMutation, { data, loading, error }] = useAttendReadingSessionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttendReadingSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttendReadingSessionMutation,
    AttendReadingSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AttendReadingSessionMutation,
    AttendReadingSessionMutationVariables
  >(AttendReadingSessionDocument, options)
}
export type AttendReadingSessionMutationHookResult = ReturnType<
  typeof useAttendReadingSessionMutation
>
export type AttendReadingSessionMutationResult =
  Apollo.MutationResult<AttendReadingSessionMutation>
export type AttendReadingSessionMutationOptions = Apollo.BaseMutationOptions<
  AttendReadingSessionMutation,
  AttendReadingSessionMutationVariables
>
export const UpdateReadingSessionPageDocument = gql`
  mutation updateReadingSessionPage($pageNumber: Int!) {
    updateReadingSessionPage(pageNumber: $pageNumber)
  }
`
export type UpdateReadingSessionPageMutationFn = Apollo.MutationFunction<
  UpdateReadingSessionPageMutation,
  UpdateReadingSessionPageMutationVariables
>

/**
 * __useUpdateReadingSessionPageMutation__
 *
 * To run a mutation, you first call `useUpdateReadingSessionPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReadingSessionPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReadingSessionPageMutation, { data, loading, error }] = useUpdateReadingSessionPageMutation({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *   },
 * });
 */
export function useUpdateReadingSessionPageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateReadingSessionPageMutation,
    UpdateReadingSessionPageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateReadingSessionPageMutation,
    UpdateReadingSessionPageMutationVariables
  >(UpdateReadingSessionPageDocument, options)
}
export type UpdateReadingSessionPageMutationHookResult = ReturnType<
  typeof useUpdateReadingSessionPageMutation
>
export type UpdateReadingSessionPageMutationResult =
  Apollo.MutationResult<UpdateReadingSessionPageMutation>
export type UpdateReadingSessionPageMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateReadingSessionPageMutation,
    UpdateReadingSessionPageMutationVariables
  >
export const EditUserDocument = gql`
  mutation editUser($data: EditUserInput) {
    editUser(data: $data) {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`
export type EditUserMutationFn = Apollo.MutationFunction<
  EditUserMutation,
  EditUserMutationVariables
>

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useEditUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditUserMutation,
    EditUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(
    EditUserDocument,
    options
  )
}
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>
export type EditUserMutationOptions = Apollo.BaseMutationOptions<
  EditUserMutation,
  EditUserMutationVariables
>
export const GetBookRecommendationsDocument = gql`
  query getBookRecommendations($id: ID!) {
    bookRecommendations(id: $id) {
      ...BookRecommendationInfo
    }
  }
  ${BookRecommendationInfoFragmentDoc}
`

/**
 * __useGetBookRecommendationsQuery__
 *
 * To run a query within a React component, call `useGetBookRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookRecommendationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookRecommendationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBookRecommendationsQuery,
    GetBookRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetBookRecommendationsQuery,
    GetBookRecommendationsQueryVariables
  >(GetBookRecommendationsDocument, options)
}
export function useGetBookRecommendationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBookRecommendationsQuery,
    GetBookRecommendationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetBookRecommendationsQuery,
    GetBookRecommendationsQueryVariables
  >(GetBookRecommendationsDocument, options)
}
export type GetBookRecommendationsQueryHookResult = ReturnType<
  typeof useGetBookRecommendationsQuery
>
export type GetBookRecommendationsLazyQueryHookResult = ReturnType<
  typeof useGetBookRecommendationsLazyQuery
>
export type GetBookRecommendationsQueryResult = Apollo.QueryResult<
  GetBookRecommendationsQuery,
  GetBookRecommendationsQueryVariables
>
export const GetBooksDocument = gql`
  query getBooks($data: GetBooksInput) {
    books(data: $data) {
      ...BookInfo
    }
  }
  ${BookInfoFragmentDoc}
`

/**
 * __useGetBooksQuery__
 *
 * To run a query within a React component, call `useGetBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBooksQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetBooksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBooksQuery, GetBooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBooksQuery, GetBooksQueryVariables>(
    GetBooksDocument,
    options
  )
}
export function useGetBooksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBooksQuery,
    GetBooksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBooksQuery, GetBooksQueryVariables>(
    GetBooksDocument,
    options
  )
}
export type GetBooksQueryHookResult = ReturnType<typeof useGetBooksQuery>
export type GetBooksLazyQueryHookResult = ReturnType<
  typeof useGetBooksLazyQuery
>
export type GetBooksQueryResult = Apollo.QueryResult<
  GetBooksQuery,
  GetBooksQueryVariables
>
export const GetBookDocument = gql`
  query getBook($id: ID!) {
    book(id: $id) {
      ...BookInfo
      ...BookInfoDetail
    }
  }
  ${BookInfoFragmentDoc}
  ${BookInfoDetailFragmentDoc}
`

/**
 * __useGetBookQuery__
 *
 * To run a query within a React component, call `useGetBookQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookQuery(
  baseOptions: Apollo.QueryHookOptions<GetBookQuery, GetBookQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBookQuery, GetBookQueryVariables>(
    GetBookDocument,
    options
  )
}
export function useGetBookLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookQuery, GetBookQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBookQuery, GetBookQueryVariables>(
    GetBookDocument,
    options
  )
}
export type GetBookQueryHookResult = ReturnType<typeof useGetBookQuery>
export type GetBookLazyQueryHookResult = ReturnType<typeof useGetBookLazyQuery>
export type GetBookQueryResult = Apollo.QueryResult<
  GetBookQuery,
  GetBookQueryVariables
>
export const GetCommentsDocument = gql`
  query getComments($refId: ID!, $type: CommentType!) {
    comments(refId: $refId, type: $type) {
      ...CommentInfo
    }
  }
  ${CommentInfoFragmentDoc}
`

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      refId: // value for 'refId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetCommentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommentsQuery,
    GetCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(
    GetCommentsDocument,
    options
  )
}
export function useGetCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommentsQuery,
    GetCommentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(
    GetCommentsDocument,
    options
  )
}
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>
export type GetCommentsLazyQueryHookResult = ReturnType<
  typeof useGetCommentsLazyQuery
>
export type GetCommentsQueryResult = Apollo.QueryResult<
  GetCommentsQuery,
  GetCommentsQueryVariables
>
export const ReadingSessionDocument = gql`
  query readingSession {
    readingSession {
      ...ReadingSessionInfo
    }
  }
  ${ReadingSessionInfoFragmentDoc}
`

/**
 * __useReadingSessionQuery__
 *
 * To run a query within a React component, call `useReadingSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useReadingSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReadingSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useReadingSessionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ReadingSessionQuery,
    ReadingSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ReadingSessionQuery, ReadingSessionQueryVariables>(
    ReadingSessionDocument,
    options
  )
}
export function useReadingSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReadingSessionQuery,
    ReadingSessionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ReadingSessionQuery, ReadingSessionQueryVariables>(
    ReadingSessionDocument,
    options
  )
}
export type ReadingSessionQueryHookResult = ReturnType<
  typeof useReadingSessionQuery
>
export type ReadingSessionLazyQueryHookResult = ReturnType<
  typeof useReadingSessionLazyQuery
>
export type ReadingSessionQueryResult = Apollo.QueryResult<
  ReadingSessionQuery,
  ReadingSessionQueryVariables
>
export const UserDocument = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(
  baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options)
}
export function useUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(
    UserDocument,
    options
  )
}
export type UserQueryHookResult = ReturnType<typeof useUserQuery>
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>
export const ViewerDocument = gql`
  query viewer {
    viewer {
      ...UserInfo
    }
  }
  ${UserInfoFragmentDoc}
`

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerQuery(
  baseOptions?: Apollo.QueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options
  )
}
export function useViewerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ViewerQuery, ViewerQueryVariables>(
    ViewerDocument,
    options
  )
}
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>
export type ViewerQueryResult = Apollo.QueryResult<
  ViewerQuery,
  ViewerQueryVariables
>
export const GetViewerWithSettingsDocument = gql`
  query getViewerWithSettings {
    viewer {
      ...UserInfo
      ...UserSettings
    }
  }
  ${UserInfoFragmentDoc}
  ${UserSettingsFragmentDoc}
`

/**
 * __useGetViewerWithSettingsQuery__
 *
 * To run a query within a React component, call `useGetViewerWithSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewerWithSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewerWithSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetViewerWithSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetViewerWithSettingsQuery,
    GetViewerWithSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GetViewerWithSettingsQuery,
    GetViewerWithSettingsQueryVariables
  >(GetViewerWithSettingsDocument, options)
}
export function useGetViewerWithSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetViewerWithSettingsQuery,
    GetViewerWithSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetViewerWithSettingsQuery,
    GetViewerWithSettingsQueryVariables
  >(GetViewerWithSettingsDocument, options)
}
export type GetViewerWithSettingsQueryHookResult = ReturnType<
  typeof useGetViewerWithSettingsQuery
>
export type GetViewerWithSettingsLazyQueryHookResult = ReturnType<
  typeof useGetViewerWithSettingsLazyQuery
>
export type GetViewerWithSettingsQueryResult = Apollo.QueryResult<
  GetViewerWithSettingsQuery,
  GetViewerWithSettingsQueryVariables
>
